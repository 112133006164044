import React from 'react';
import { Provider } from 'react-redux';
import { initializeStore, injectAsyncReducer, runSaga } from './store';
import App from 'next/app';

export const withRedux = (
  PageComponent,
  { additionalReducers = {}, additionalSagas = {} } = {}
) => {
  const WithRedux = ({ initialReduxState, ...props }) => {
    const store = getOrInitializeStore(
      initialReduxState,
      additionalReducers,
      additionalSagas
    );
    return (
      <Provider store={store}>
        <PageComponent {...props} />
      </Provider>
    );
  };

  // Make sure people don't use this HOC on _app.js level
  if (process.env.NODE_ENV !== 'production') {
    const isAppHoc =
      PageComponent === App || PageComponent.prototype instanceof App;
    if (isAppHoc) {
      throw new Error('The withRedux HOC only works with PageComponents');
    }
  }

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName =
      PageComponent.displayName || PageComponent.name || 'Component';

    WithRedux.displayName = `withRedux(${displayName})`;
  }

  WithRedux.getInitialProps = async context => {
    // Get or Create the store with `undefined` as initialState
    // This allows you to set a custom default initialState

    const reduxStore = getOrInitializeStore(
      undefined,
      additionalReducers,
      additionalSagas
    );

    // Provide the store to getInitialProps of pages
    context.reduxStore = reduxStore;

    // Run getInitialProps from HOCed PageComponent
    const pageProps =
      typeof PageComponent.getInitialProps === 'function'
        ? await PageComponent.getInitialProps(context)
        : {};

    // Pass props to PageComponent
    return {
      ...pageProps,
      initialReduxState: reduxStore.getState(),
    };
  };

  return WithRedux;
};

let reduxStore;
const runningSagas = {};
const injectedReducers = {};
export const getOrInitializeStore = (
  initialState,
  additionalReducers,
  additionalSagas
) => {
  // Always make a new store if server, otherwise state is shared between requests
  if (typeof window === 'undefined') {
    return initializeStore(initialState, additionalReducers);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!reduxStore) {
    reduxStore = initializeStore(initialState, additionalReducers);
  }

  if (typeof window !== 'undefined') {
    for (let [sagaKey, saga] of Object.entries(additionalSagas)) {
      if (runningSagas[sagaKey]) {
        continue;
      }
      runSaga(saga);
      runningSagas[sagaKey] = saga;
    }
  }

  for (let [reducerKey, reducer] of Object.entries(additionalReducers)) {
    if (injectedReducers[reducerKey]) {
      continue;
    }
    injectAsyncReducer(reduxStore, reducerKey, reducer);
    injectedReducers[reducerKey] = reducer;
  }

  return reduxStore;
};
