export function isUserOrganiser(user) {
  return (
    isGranted(user, 'ROLE_COUNTRY_ORGANISER') ||
    isGranted(user, 'ROLE_WORLD_ORGANISER')
  );
}

export default function isGranted(user, role) {
  return user && user.roles && user.roles.indexOf(role) !== -1;
}
