const refreshUser = apiClient =>
  apiClient(
    '/api/users/me?' +
      'fields[user]=displayName,firstname,lastname,jobs,roles,email,disciplines,jobs,unreadMessages,unreadAlerts,countColleagues,hasCompletedAllRecommendedFields,emailIFPCommunication,emailNetworkEvents,communityGroupsIntroNeeded,isExpert' +
      '&fields[country]=name,code,primaryColor,secondaryColor' +
      '&includes=country,avatar,partnership' +
      '&fields[partnership]=businessSector,activities,otherActivities,name,structureType' +
      '&fields[file]=filename'
  );

export default refreshUser;
