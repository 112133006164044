import uniqid from 'uniqid';

const ADD_FLASH_MESSAGE = 'flashMessages/ADD_FLASH_MESSAGE';
const REMOVE_FLASH_MESSAGE = 'flashMessages/REMOVE_FLASH_MESSAGE';

export const MESSAGE_TYPE_SUCCESS = 'success';
export const MESSAGE_TYPE_ERROR = 'error';
export const MESSAGE_TYPE_INFO = 'info';

export const addFlashMessageAction = (
  text,
  messageType = MESSAGE_TYPE_SUCCESS
) => ({
  type: ADD_FLASH_MESSAGE,
  text,
  id: uniqid(),
  messageType,
});

export const removeFlashMessageAction = id => ({
  type: REMOVE_FLASH_MESSAGE,
  id,
});

export const flashMessagesInitialState = {
  messages: [],
};

export default function reducer(state = flashMessagesInitialState, action) {
  switch (action.type) {
    case ADD_FLASH_MESSAGE: {
      return {
        ...state,
        messages: [
          ...state.messages,
          { text: action.text, id: action.id, type: action.messageType },
        ],
      };
    }
    case REMOVE_FLASH_MESSAGE: {
      return {
        ...state,
        messages: state.messages.filter(message => message.id !== action.id),
      };
    }
    default:
      return state;
  }
}
