const COUNTRIES_FETCHED = 'header/COUNTRIES_FETCHED';

export const countriesFetchedAction = countries => ({
  type: COUNTRIES_FETCHED,
  countries,
});

export const headerInitialState = {
  countries: [],
};

export default function reducer(state = headerInitialState, action) {
  switch (action.type) {
    case COUNTRIES_FETCHED: {
      return {
        ...state,
        countries: action.countries,
      };
    }
    default:
      return state;
  }
}
