const { getJWT, setJWT } = require('./jwtRepository');
const cookie = require('cookie');
const dayjs = require('dayjs');

let jwtHeader;
if (typeof window === 'undefined') {
  const loadEnv = require('../loadEnv');
  const config = loadEnv();
  jwtHeader = config.JWT_HEADER;
} else {
  const getConfig = require('next/config').default;
  const envConfig = getConfig().publicRuntimeConfig;
  jwtHeader = envConfig.JWT_HEADER;
}
if (!jwtHeader) {
  jwtHeader = 'Authorization';
}

async function buildApiClientFromNextJsContext(context) {
  if (typeof window === 'undefined') {
    return buildApiClientFromRequestAndResponse(context.req, context.res);
  }

  return buildApiClientForBrowser();
}

let apiPrivateHost = null;

async function buildApiClientFromRequestAndResponse(req, res) {
  const cookies = cookie.parse(req.headers.cookie || '');

  if (typeof window === 'undefined' && apiPrivateHost === null) {
    const loadEnv = require('../loadEnv');
    const config = loadEnv();
    apiPrivateHost = process.env.API_PRIVATE_HOST;
  }

  return makeApiClient(
    cookies.JWT,
    apiPrivateHost,
    (url, options, response) => {
      // if we got a 401 with a JWT cookie, we drop the cookie and fetch again
      if (cookies.JWT) {
        res.setHeader(
          'Set-Cookie',
          'JWT=""; expires=Thu, 01 Jan 1970 00:00:00 GMT'
        );
        delete options.headers[jwtHeader];

        return fetch(url, options);
      }

      return response;
    }
  );
}

const makeApiClient = (jwt, apiPrivateHost, on401Response) => {
  return (url, options = {}) => {
    const transformedOptions = { ...options };

    if (jwt) {
      if (!transformedOptions.headers) {
        transformedOptions.headers = {};
      }
      transformedOptions.headers.cookie = cookie.serialize('JWT', jwt, {
        httpOnly: true,
        sameSite: 'lax',
        expires: dayjs()
          .add(1, 'day')
          .toDate(),
        path: '/',
      });
    }

    let apiHost = typeof window === 'undefined' ? apiPrivateHost : '';

    let fullUrl = `${apiHost}${url}`;
    if (options.encodeURI !== false) {
      fullUrl = encodeURI(`${apiHost}${url}`);
    }

    return fetch(fullUrl, transformedOptions).then(response => {
      if (response.status === 401) {
        return on401Response(fullUrl, transformedOptions, response);
      }

      return response;
    });
  };
};

async function buildApiClientForBrowser() {
  return makeApiClient(null, null, (url, options) => {
    // if we had no JWT, we logout against the API and reload the whole page
    return logoutAndReloadPage();
  });
}

async function logoutAndReloadPage() {
  return fetch('/api/logout', { method: 'POST' }).then(() => {
    window.location.reload();

    return new Promise(() => {});
  });
}

module.exports = {
  buildApiClientFromNextJsContext,
  buildApiClientFromRequestAndResponse,
  buildApiClientForBrowser,
};
