const PRIVATE_GROUPS_FETCHED = 'privateGroups/GROUPS_FETCHED';

export const privateGroupsFetchedAction = (groups, userId) => ({
  type: PRIVATE_GROUPS_FETCHED,
  groups,
  userId,
});

export const privateGroupsInitialState = {
  groups: null,
};

export default function reducer(state = privateGroupsInitialState, action) {
  switch (action.type) {
    case PRIVATE_GROUPS_FETCHED: {
      return {
        ...state,
        groups: action.groups,
        userId: action.userId,
      };
    }
    default:
      return state;
  }
}
