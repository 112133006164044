const USER_LOGGED = 'authentication/USER_LOGGED';
const RELOAD_USER = 'authentication/RELOAD_USER';
const READ_ALERTS = 'authentication/READ_ALERTS';
export const USER_DISCONNECTED = 'authentication/USER_DISCONNECTED';
export const USER_MAX_AGE = 900000; // 15 min = 900000 ms

export const userLoggedAction = user => ({
  type: USER_LOGGED,
  user,
});

export const reloadUserAction = user => ({
  type: RELOAD_USER,
  user,
});

export const userReadAlerts = () => ({
  type: READ_ALERTS,
});

export const userDisconnectedAction = () => ({
  type: USER_DISCONNECTED,
});

export const authenticationInitialState = {
  user: null,
};

export default function reducer(state = authenticationInitialState, action) {
  switch (action.type) {
    case 'chat/DISCUSSION_LEFT': {
      if (action.discussion.muted || action.discussion.unreadMessages === 0) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          unreadMessages:
            state.user.unreadMessages - action.discussion.unreadMessages,
        },
      };
    }

    case 'chat/DISCUSSION_MUTE_STATUS_UPDATED': {
      const unreadMessages =
        state.user.unreadMessages +
        (action.muted ? -action.unreadMessages : action.unreadMessages);

      return {
        ...state,
        user: {
          ...state.user,
          unreadMessages,
        },
      };
    }

    case 'chat/EVENT_RECEIVED': {
      if (
        action.event.__TYPE__ !== 'userPostedMessage' ||
        action.event.author?.id === state.user.id ||
        action.eventIsOnCurrentDiscussion ||
        action.discussionIsMuted
      ) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          unreadMessages: state.user.unreadMessages + 1,
        },
      };
    }

    case 'chat/USER_READ_MESSAGES': {
      if (action.discussionIsMuted) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          unreadMessages: Math.max(
            0,
            state.user.unreadMessages - action.readMessages
          ),
        },
      };
    }
    case READ_ALERTS: {
      return {
        ...state,
        user: {
          ...state.user,
          unreadAlerts: 0,
        },
      };
    }
    case RELOAD_USER:
    case USER_LOGGED: {
      return {
        ...state,
        user: action.user
          ? {
              ...action.user,
              refreshedAt: Date.now(),
            }
          : null,
      };
    }
    case USER_DISCONNECTED: {
      return {
        ...state,
        user: null,
      };
    }
    default:
      return state;
  }
}
