import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineReducers } from 'redux';
import authenticationReducer, {
  authenticationInitialState,
} from './modules/authentication';
import flashMessagesReducer, {
  flashMessagesInitialState,
} from './modules/flashMessages';
import headerReducer, { headerInitialState } from './modules/header';
import currentCountryReducer, {
  currentCountryInitialState,
} from './modules/currentCountry';
import createSagaMiddleware from 'redux-saga';
import privateGroupsReducer, {
  privateGroupsInitialState,
} from './modules/privateGroups';

const initialState = {
  authentication: authenticationInitialState,
  flashMessages: flashMessagesInitialState,
  header: headerInitialState,
  currentCountry: currentCountryInitialState,
  privateGroups: privateGroupsInitialState,
};

const createReducer = (asyncReducers = {}) => {
  return combineReducers({
    authentication: authenticationReducer,
    flashMessages: flashMessagesReducer,
    header: headerReducer,
    currentCountry: currentCountryReducer,
    privateGroups: privateGroupsReducer,
    ...asyncReducers,
  });
};

const sagaMiddleware = createSagaMiddleware();
export const initializeStore = (
  preloadedState = initialState,
  asyncReducers = {}
) => {
  const store = createStore(
    createReducer(asyncReducers),
    preloadedState,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );

  store.asyncReducers = asyncReducers;

  return store;
};

export const injectAsyncReducer = (store, name, reducer) => {
  store.asyncReducers[name] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};

export const runSaga = saga => {
  sagaMiddleware.run(saga);
};
