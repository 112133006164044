const COUNTRY_FETCHED = 'currentCountry/COUNTRY_FETCHED';
const RESET_CURRENT_COUNTRY = 'currentCountry/RESET_COUNTRY';

export const currentCountryFetchedAction = country => ({
  type: COUNTRY_FETCHED,
  country,
});

export const resetCurrentCountryAction = () => ({
  type: RESET_CURRENT_COUNTRY,
});

export const currentCountryInitialState = {
  country: null,
};

function formatNumber(number) {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return number;
}

export default function reducer(state = currentCountryInitialState, action) {
  switch (action.type) {
    case RESET_CURRENT_COUNTRY: {
      return {
        ...state,
        country: null,
      };
    }
    case COUNTRY_FETCHED: {
      return {
        ...state,
        country: {
          ...action.country,
          countPublications: formatNumber(
            action.country.countPublications.total
          ),
          countGroups: formatNumber(action.country.countGroups),
          countUsers: formatNumber(action.country.countUsers.total),
        },
      };
    }
    default:
      return state;
  }
}
