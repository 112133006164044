const localforage = require('localforage');

const JWT_KEY = 'jwt';

function getJWT() {
  return localforage.getItem(JWT_KEY);
}

function setJWT(jwt) {
  return localforage.setItem(JWT_KEY, jwt);
}

module.exports = {
  getJWT,
  setJWT,
};
